/*
Edited by JHLEE
Manager Page Loading && Default Events Initailizing
*/


!function ($) {
    "use strict";

    var Components = function () { };

    Components.prototype.initShowPassword = function () {
        $("[data-password]").on('click', function() {
            if($(this).attr('data-password') == "false"){
                $(this).siblings("input").attr("type", "text");
                $(this).attr('data-password', 'true');
                $(this).addClass("show-password");
            } else {
                $(this).siblings("input").attr("type", "password");
                $(this).attr('data-password', 'false');
                $(this).removeClass("show-password");
            }
        });
    },

    Components.prototype.initMultiDropdown = function () {
        $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
            if (!$(this).next().hasClass('show')) {
              $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
            }
            var $subMenu = $(this).next(".dropdown-menu");
            $subMenu.toggleClass('show');
    
            return false;
        });   
    },

    //initilizing
    Components.prototype.init = function () {
        this.initShowPassword();
        this.initMultiDropdown();
    },

    $.Components = new Components, $.Components.Constructor = Components

}(window.jQuery),

function ($) {
    'use strict';

    var App = function () {
        this.$body = $('body'),
        this.$window = $(window)
    };

    /** 
     * Initlizes the controls
    */
    App.prototype.initControls = function () {
        // remove loading
        setTimeout(function() {
            document.body.classList.remove('loading');
        }, 400);
        
        // Preloader
        $(window).on('load', function () {
            $('#status').fadeOut();
            $('#preloader').delay(350).fadeOut('slow');
        });

    },

    //initilizing
    App.prototype.init = function () {
        $.Components.init();

        this.initControls();

        // init layout
        this.layout = $.LayoutThemeApp;
        this.layout.init();
    
    },

    $.App = new App, $.App.Constructor = App


}(window.jQuery),
//initializing main application module
function ($) {
    "use strict";
    $.App.init();
}(window.jQuery);

// Waves Effect
Waves.init();
